import axios from 'axios';
import config from '../config/env-constants';

export default {
  baseUrl: `https://us-central1-elog-${config.currEnv === 'prod' ? '' : config.currEnv + '-'}app.cloudfunctions.net`,

  setUserSessionAttempts(params) {
    let baseUrl = `${this.baseUrl}/loginUserSessionAttempts`;
    return axios.post(baseUrl, {
      emailAddress: params.emailAddress,
      deviceType: params.deviceType
    });
  }
}