import config from '../config/env-constants';

export const UserUtil = {
    // Notification Messages
    INACTIVE_USER_MSG: `Access Denied: Your account is currently inactive. Please contact the administrator (${config.adminAccount.ELOG_SUPPORT}) for assistance.`,

    isAccountActive(currUser) {
        return currUser.isActive === true;
    }
}
