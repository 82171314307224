<template>
	<!-- Modal Component -->
	<b-modal id="forgot-password" ref="modal" title="Forgot Password?" @ok="handleOk" @shown="clearEmail">
		<label>
			Enter your email address linked to your account. Then, click OK. You will
			receive an email with the link to change your password.
		</label>
		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-form-input v-model="email" name="Email" type="email" placeholder="Enter your email address"
				required></b-form-input>
		</b-form>
	</b-modal>
</template>

<script>
import { auth } from '../../config/firebase';

export default {
	name: 'forgot-password',
	data() {
		return {
			email: ''
		};
	},
	methods: {
		clearEmail() {
			this.email = '';
		},
		handleOk(evt) {
			evt.preventDefault();
			if (!this.email) {
				this.$toast.warning('Please enter your email address');
			}

			this.handleSubmit();
		},
		handleSubmit() {
			auth.sendPasswordResetEmail(this.email)
				.then(() => {
					this.$refs.modal.hide();
					this.$toast.success('Reset password email was sent!');
				})
				.catch(error => {
					this.$toast.error(error);
				});
		}
	}
};
</script>
