<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">

          <div class="text-center text-muted mb-4">
            <img src="img/brand/brand.png" class="img-fluid pb-2" width="60%" alt="brand-logo" />
            <h2 class="font-weight-light text-gray">Employee Work Log</h2>
          </div>

          <form role="form">
            <ValidationProvider name="Email" rules="required|email">
              <div slot-scope="{ errors }">
                <div class="text-right">
                  <small class="text-danger text-right">{{ errors[0] }}</small>
                </div>
                <base-input class="input-group-alternative mb-3" placeholder="Email" addon-left-icon="ni ni-email-83"
                  v-model="form.email" />
              </div>
            </ValidationProvider>

            <ValidationProvider name="Password" rules="required|digits:6">
              <div slot-scope="{ errors }">
                <div class="text-right">
                  <small class="text-danger text-right">{{ errors[0] }}</small>
                </div>
                <base-input class="input-group-alternative" placeholder="Password" type="password"
                  addon-left-icon="ni ni-lock-circle-open" v-model="form.password" maxlength="6" />
              </div>
            </ValidationProvider>

            <div class="text-center">
              <base-button type="primary" block class="my-4" @click="login" :disabled="isAuthenticating">
                <span v-if="isAuthenticating">
                  <i class="fas fa-spinner fa-spin"></i>
                </span>
                <span v-else>Sign in</span>
              </base-button>
              <div class="text-right">
                <a class="forgot-password-btn" v-b-modal.forgot-password>
                  Forgot Password
                </a>
              </div>
            </div>

            <base-alert class="mt-3" v-if="error" type="danger">
              <span class="alert-inner--text">{{ error }}</span>
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true" @click="error = ''">&times;</span>
              </button>
            </base-alert>

          </form>
        </div>
      </div>
    </div>

    <forgot-password />
  </div>
</template>

<script>
// Components
import ForgotPassword from "@/views/auth/ForgotPassword.vue";

// Utils
import { UserUtil } from "@/utils/user";
import { CompanyUtil } from "@/utils/company"

// API & DAO
import api from "@/api/userSession";
import { verifyLoginAttempts, getUserDetailsByEmail } from "@/database/users";
import companyDAO from "@/database/companies"


export default {
  name: "login",
  components: { ForgotPassword },
  data() {
    return {
      isAuthenticating: false,
      form: {
        email: "",
        password: "",
      },
      error: "",
    };
  },
  methods: {
    async validateLogin() {
      let isValid = true;

      if (_.isEmpty(this.form.email)) {
        this.error = "Email address is required.";
        isValid = false;

      } else if (_.isEmpty(this.form.password)) {
        this.error = "Password is required.";
        isValid = false;

      } else {
        let isLocked = await verifyLoginAttempts(this.form.email);
        let userObj = await getUserDetailsByEmail(this.form.email);
        let companyObj = await companyDAO.getCompanyById(userObj.companyId);

        if (!CompanyUtil.isCompanyActive(companyObj)) {
          this.error = CompanyUtil.INACTIVE_COMPANY_MSG;
          isValid = false;
        } else if (!UserUtil.isAccountActive(userObj)) {
          this.error = UserUtil.INACTIVE_USER_MSG;
          isValid = false;
        } else if (isLocked) {
          this.error = `Your account has been locked due to multiple attempts. Please reset your password to proceed.`;
          isValid = false;
        }
      }

      return isValid;
    },
    async login() {
      try {
        this.isAuthenticating = true;

        let isValid = await this.validateLogin();
        if (!isValid) {
          this.isAuthenticating = false;
          return;
        }

        // pre-process form
        this.form.email = this.form.email.toLowerCase().trim();
        // proceed to login
        await this.$store.dispatch("auth/login", {
          email: this.form.email,
          password: this.form.password,
        });

      } catch (error) {
        this.error = error.message;
        await this.setAttempts(error.code);
      }

      this.isAuthenticating = false;
    },
    async setAttempts(errCode) {
      if (errCode === "auth/wrong-password") {
        let userAttempts = {
          emailAddress: this.form.email,
          deviceType: "web",
        };

        await api.setUserSessionAttempts(userAttempts);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.forgot-password-btn {
  margin-top: 0px;
  cursor: pointer;
}
</style>
