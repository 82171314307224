import { db } from '../config/firebase';


async function getCompanyById(companyId) {
  const query = db.collection('companies').doc(companyId);
  const querySnapshot = await query.get();
  return querySnapshot.data();
}

export default {
  getCompanyById
}